import React, { useState, useEffect } from 'react';
import { Endpoint } from '../util/endpoint.util';
import useEndpoint from '../hooks/endpoint.hook';

import {
  Typography,
  Card,
  CardContent,
  Grid,
  Paper,
  Box,
  Divider,
  Button,
} from '@mui/material';

import AlertMessageComponent from '../components/AlertMessage';
import { useGarageLocation } from '../components/Location';


async function CardNumfromStallNum(location: string, stall_num: number, endpoint: Endpoint): Promise<number> {
  const res = await endpoint.get('sotefin/api/stall_num_to_card_num', {
    params: {
      location: location,
      stall: stall_num
    }
  })
  const card_num = res.data.card_num
  return card_num
}

const Sotefin = () => {
  const endpoint = useEndpoint();
  const { garageLocation } = useGarageLocation()!;

  const [message, setMessage] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const [swapQueue, setSwapQueue] = useState([]);
  const [exitQueue, setExitQueue] = useState([]);
  const [evStalls, setEvStalls] = useState<any[]>([]);

  const [holsterMap, setHolsterMap] = useState([]);

  useEffect(() => {
    endpoint?.get('sotefin/api/get_swap_queue', {
      params: {
        location: garageLocation,
      },
      withCredentials: true
    }).then(response => {
        // console.log('queue', response.data);
        setSwapQueue(response.data);
    }).catch(error => {
      console.error('There was an error getting the swap queue!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [garageLocation, endpoint]);
  
  useEffect(() => {
    endpoint?.get('sotefin/api/get_exit_queue', {
      params: {
        location: garageLocation
      },
      withCredentials: true
    }).then(response => {
        // console.log('queue', response.data);
        setExitQueue(response.data);
    }).catch(error => {
      console.error('There was an error getting the exit queue!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [garageLocation, endpoint]);

  useEffect(() => {
    // deprecate after integrating into ui
    endpoint?.get('holster_checker/api/holster_map_for_garage', {
      params: {
        location: garageLocation
      },
      withCredentials: true
    }).then(response => {
      const holsterMap = response.data.holsterMap
      console.log('holster_map', holsterMap);
      setHolsterMap(response.data);
      console.log(evStalls)
    }).catch(error => {
      console.error('There was an error getting the holster map!', error);
    });
  }, [garageLocation, evStalls, endpoint]);

  useEffect(() => {
    endpoint?.get('sotefin/api/get_ev_stalls', {
      params: {
        location: garageLocation
      },
      withCredentials: true
    }).then(async response => {
        let queue = response.data;
        
        for (let i = 0; i < queue.length; i++) {
          let stalls = []
          for (let j = 0; j < queue[i]['stall_nums'].length; j++) {
            const card_num = await CardNumfromStallNum(garageLocation, queue[i]['stall_nums'][j], endpoint); // Resolve the Promise here
            stalls.push({
              stall_num : queue[i]['stall_nums'][j],
              card_num : card_num
            })
          }
          queue[i]['stalls'] = stalls
        }

        // console.log('queue', queue);
        setEvStalls(queue);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [garageLocation, endpoint]);

  const swapCar = (card_num: number) => {
    endpoint?.post('sotefin/api/swap_car', {
      params: {
        location: garageLocation,
        // stall_num: stall_num
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const exitCar = (card_num: number) => {
    endpoint?.post('sotefin/api/exit_car', {
      params: {
        location: garageLocation,
        // stall_num: stall_num
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  return(
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Sotefin Control
      </Typography>

      <QueueCards title="Swap Queue" queue={swapQueue}/>
      <QueueCards title="Exit Queue" queue={exitQueue}/>

      <Paper elevation={2} sx={{ marginY: 2 }}>
        <SwapCards swapPropsArray={evStalls} swapCar={swapCar} exitCar={exitCar}/>
      </Paper>
      <Paper elevation={2} sx={{ marginY: 2 }}>
        <HolsterMapDisplay holsterMap={holsterMap}/>
      </Paper>
      <AlertMessageComponent message={message} isErrorMessage={isErrorMessage} />
    </Box>
  )
};

interface Car {
  id: string;
  card: string;
  slot: string;
  size: string;
}

const HolsterMapDisplay: React.FC<{holsterMap: any}> = (holsterMap: any) => {
  return(
    <>
      <Typography variant="h4" align="center" gutterBottom>
        Holster Map Testing
      </Typography>
      <Grid container justifyContent="flex-end">
        <Grid item>
        <AlertMessageComponent message={holsterMap} isErrorMessage={false} />
        </Grid>
      </Grid>
    </>
  )
}

interface SwapProps {
  evse_location: string;
  stall_nums: number[];
  stalls: {stall_num: number, card_num: number}[]
}

interface SwapCardsProps {
  swapPropsArray: SwapProps[];
  swapCar: (stall_num: number) => void;
  exitCar: (stall_num: number) => void;
}

const SwapCards: React.FC<SwapCardsProps> = ({ swapPropsArray, swapCar, exitCar }) => {

  const [stallNumber, setStallNumber] = useState<string>('');
  const [cardNumber, setCardNumber] = useState<number>(0);

  return(
    <>
      <Typography variant="h4" align="center" gutterBottom>
        EV Stalls
      </Typography>
      {swapPropsArray.map((swapProps) => (
        <Paper elevation={2} sx={{ marginY: 2, marginX: 2 }} key={swapProps['evse_location']}>
          <Typography variant="h6" align="center">
            {swapProps['evse_location']}
          </Typography>
          <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
            {/* {swapProps['stall_nums'].map(stall_num => ( */}
            {swapProps['stalls'].map((stall: any) => (
              <SwapCard stall_num={stall['stall_num']} card_num={stall['card_num']} setCardNumber={setCardNumber} stallNumber={stallNumber} setStallNumber={setStallNumber} />
            ))}
            <Grid item>
              
            </Grid>
          </Grid>
        </Paper>
      ))}
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Button 
            variant="contained" 
            onClick={() => swapCar(cardNumber)}
            sx={{ margin: 2 }}
          >
            Swap Car
          </Button>
        </Grid>
        <Grid item>
          <Button 
            variant="contained" 
            onClick={() => exitCar(cardNumber)}
            sx={{ margin: 2 }}
          >
            Exit Car
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

interface SwapCardProps {
  stall_num: number;
  card_num: number;
  setCardNumber: (card_num: number) => void;
  stallNumber: string;
  setStallNumber: (stall_num: string) => void;
}
const SwapCard: React.FC<SwapCardProps> = ({ stall_num, card_num, setCardNumber, stallNumber, setStallNumber }) => {
  const { garageLocation } = useGarageLocation()!;
  const endpoint = useEndpoint();

  const [okToPlug, setOkToPlug] = useState('n/a');
  const [isHolstered, setIsHolstered] = useState('n/a');

  useEffect(() => {
    endpoint?.get('e2e/api/vehicle_ok_to_plug_in', {
      params: {
        location: garageLocation,
        card_num: card_num
      },
      withCredentials: true
    }).then(response => {
        // console.log(`checking if vehicle ${card_num} at ${garageLocation} is ok to plug in:`, response.data);
        setOkToPlug(`${response.data}`);
    }).catch(error => {
      console.error(`There was an error finding out if vehicle ${card_num} at ${garageLocation} is ok to plug in!: ${error}`);
      setOkToPlug('n/a');
    });
  }, [garageLocation, endpoint, card_num]);

  useEffect(() => {
    endpoint?.get('holster_checker/api/holster_status_for_stall', {
      params: {
        location: garageLocation,
        stall_num: stall_num
      },
      withCredentials: true
    }).then(response => {
        // console.log(response.data);
        setIsHolstered(`${response.data.is_holstered}`);
    }).catch(error => {
      console.error(`There was an error finding out holster status at stall ${stall_num} at ${garageLocation}!: ${error}`);
    });
  }, [garageLocation, endpoint, stall_num]);

  return (
    <Grid item sx={{ minWidth: 120 }} key={stall_num}>
      <Button onClick={ () => {
        setCardNumber(card_num);
        setStallNumber(stall_num.toString())
      }}>
        {stall_num === parseInt(stallNumber) ?
        <Card sx={{ border: '2px solid green' }}>
          <CardContent>
            <Typography variant="subtitle1">
              <b>stall_num: {stall_num}</b>
            </Typography>
            <Typography variant="subtitle2">
              <b>is_holstered: {isHolstered}</b>
            </Typography>
            <Typography variant="subtitle1">
              <b>card_num: {card_num}</b>
            </Typography>
            <Typography variant="subtitle2">
              <b>ok_to_plug: {okToPlug}</b>
            </Typography>
          </CardContent>
        </Card>
        :
        <Card>
          <CardContent>
            <Typography variant="subtitle1">
              stall_num: {stall_num}
            </Typography>
            <Typography variant="subtitle2">
              is_holstered: {isHolstered}
            </Typography>
            <Typography variant="subtitle1">
              card_num: {card_num}
            </Typography>
            <Typography variant="subtitle2">
              ok_to_plug: {okToPlug}
            </Typography>
          </CardContent>
        </Card>
        }
        
      </Button>
    </Grid>
  )
}

interface QueueProps {
  title: string;
  queue: Car[];
}

const QueueCards: React.FC<QueueProps> = ({ title, queue }) => {
  return (
    <Paper elevation={2} sx={{ marginY: 2 }}>
      <Typography variant="h5" align="center">
        {title}
      </Typography>

      <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
        {queue.map((car) => (
          <Grid item xs={6} sm={4} md={2} key={car.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" align="center">
                  {car.id}
                </Typography>
                <Divider variant="middle" />
                <Typography variant="subtitle1">
                  card_num: {car.card}
                </Typography>
                <Typography variant="subtitle1">
                  stall_num: {car.slot}
                </Typography>
                <Typography variant="subtitle1">
                  size: {car.size}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

    </Paper>
  );
};

export default Sotefin;