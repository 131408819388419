import React, { createContext, useContext, ReactNode, ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

type AuthContextType = {
  isLoggedIn: boolean;
  login: () => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  return useContext(AuthContext);
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout
  } = useAuth0();

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isAuthenticated,
        login: loginWithRedirect,
        logout: () => logout(),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

type ProtectedRouteProps = {
  element: ReactElement;
};

export const AuthProtectedRoute = ({ element }: ProtectedRouteProps) => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();

  if (isLoading)
    return null;

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return element;
};