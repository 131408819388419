import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export const useAccessToken = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const {
    getAccessTokenSilently, 
    isAuthenticated,
  } = useAuth0();

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            detailedResponse: true,
            audience: 'https://parkworksus.com',
            scope: process.env.REACT_APP_AUTH0_SCOPE,
          }
        });
        setAccessToken(token);
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated)
      fetchAccessToken();
  }, [getAccessTokenSilently, isAuthenticated]);

  return accessToken;
};

export const useRawAccessToken = () => {
  const accessToken = useAccessToken();

  return accessToken;
}