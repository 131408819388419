import axios, { AxiosRequestConfig } from "axios";

export class Endpoint {
  constructor(private authToken: string) {}

  public get(endpoint: string, params?: AxiosRequestConfig) {
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        ...params?.headers,
      },
      ...params,
    });
  }

  public post(endpoint: string, params: AxiosRequestConfig) {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        ...params?.headers,
      },
      ...params,
    });
  }

  public put(endpoint: string, params: AxiosRequestConfig) {
    return axios({
      method: "put",
      url: `${process.env.REACT_APP_BACKEND_URL}/${endpoint}`,
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${this.authToken}`,
        ...params?.headers,
      },
      ...params,
    });
  }
}

export default Endpoint;
