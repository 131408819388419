import React, { useState } from 'react';
import { useEndpoint } from '../hooks/endpoint.hook';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Card,
  Box,
  Typography,
  CardContent
} from '@mui/material';


import AlertMessageComponent from '../components/AlertMessage';

import { useGarageLocation } from '../components/Location';
import { useCarNumber } from '../components/TeslaCars';

const Tesla = () => {
  const { garageLocation } = useGarageLocation()!;
  const { carNumber, carNumbers, carNumberSelected, setTheCarNumber } = useCarNumber()!;
  const endpoint = useEndpoint();

  const [message, setMessage] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const handleGetUserInfo = () => {
    endpoint?.get('tesla/api/get_user_info_by_card_num', {
      params: { card_num: carNumber, location: garageLocation },
      withCredentials: true
    })
    .then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleOpenChargePort = () => {
    endpoint?.post('tesla/api/unlock_chargeport_door', {
      params: { card_num: carNumber, location: garageLocation},
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage("Charge port opened successfully.");
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleGetChargingStatus = () => {
    endpoint?.get('tesla/api/get_charge_state', {
      params: { card_num: carNumber, location: garageLocation },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Tesla Control
      </Typography>
      <Card>
        <CardContent>
          <FormControl fullWidth>
            <InputLabel>Car</InputLabel>
            <Select
              value={carNumber}
              onChange={(event) => setTheCarNumber(event.target.value as string)}
            >
              {carNumbers.map(car => (
                <MenuItem key={car} value={car}>{car}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
        <CardContent>
          <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <Button 
                variant="contained" 
                onClick={handleGetUserInfo} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Get User Info
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 240 }}>
              <Button 
                variant="contained" 
                onClick={handleOpenChargePort} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Open Charge Port / Release Charger
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <Button 
                variant="contained" 
                onClick={handleGetChargingStatus} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Charging Status
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AlertMessageComponent message={message} isErrorMessage={isErrorMessage} />
    </Box>
  );
};

export default Tesla;
