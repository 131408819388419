import React, { createContext, useContext, useState, useEffect, ReactNode, ReactElement } from 'react';
import { Box, Typography, Container, CssBaseline } from '@mui/material';
import { useAuth } from '../components/Auth';
import useEndpoint from '../hooks/endpoint.hook';

type GarageLocationContextType = {
  garageLocation: string;
  garageLocations: string[];
  garageLocationSelected: boolean;
  setTheGarageLocation: (garageLocation:string) => void;
};

const GarageLocationContext = createContext<GarageLocationContextType | null>(null);

export const useGarageLocation = () => {
  return useContext(GarageLocationContext);
}; 

type GarageLocationProviderProps = {
  children: ReactNode;
};

export const GarageLocationProvider = ({ children }: GarageLocationProviderProps) => {
  const endpoint = useEndpoint();
  const { isLoggedIn } = useAuth()!;
  const [garageLocations, setGarageLocations] = useState(['']);
  const [garageLocation, setGarageLocation] = useState(
    () => localStorage.getItem('garageLocation') || ''
  );

  const setTheGarageLocation = (location: string) => {
    setGarageLocation(location);
  };

  useEffect(() => {
    if (isLoggedIn) {
      endpoint
        ?.get('sotefin/api/get_garage_locations')
        .then(response => {
          console.log('locations:', response.data);
          // This was done for a reason to fix mobile not working dont change it!!!
          setGarageLocations([...(response.data as unknown as string[])]);
        }).catch((error) => {
          console.error(error)
          console.error(error.stack)
          if (error.response) {
            // Check if the error has a response object
            console.error("There was an error!", error.response.data.message);
          }
      });
    }
  }, [isLoggedIn, endpoint]);

  useEffect(() => {
    localStorage.setItem('garageLocation', garageLocation);
  }, [garageLocation]);

  const value = {
    garageLocation,
    garageLocations,
    setTheGarageLocation,
    garageLocationSelected: !!garageLocation,
  };

  return <GarageLocationContext.Provider value={value}>{children}</GarageLocationContext.Provider>;
};

type ProtectedRouteProps = {
  element: ReactElement;
};

export const GarageLocationProtectedRoute = ({ element }: ProtectedRouteProps) => {
  const { garageLocationSelected } = useGarageLocation()!;
  if (!garageLocationSelected) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Please select a location from above
          </Typography>
        </Box>
      </Container>
    )
  }

  return element;
};