import React, { useState, useEffect } from 'react';

import {
  CardActions,
  Card,
  Box,
  Typography,
  CardContent,
  Link
} from '@mui/material';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useGarageLocation } from '../components/Location';
import { useEndpoint } from '../hooks/endpoint.hook';
import { Endpoint } from '../util/endpoint.util';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

interface Report {
  [key: string]: {
    [key: string]: string;
  };
}

const Reports= () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const endpoint = useEndpoint();
  const [reports, setReports] = useState<Report>({});
  const { garageLocation } = useGarageLocation()!;

  const getReports = (endpoint: Endpoint) => {
    return endpoint?.get('report/api/reports')
      .then(response => response.data)
      .catch(console.error)
  };

  useEffect(() => {
    endpoint && getReports(endpoint).then(reports => setReports(reports as unknown as any));
  }, [endpoint]); 

  function getReportZipFileLink(report: any): string {
    const key = Object.keys(report).find(key => key.includes('.zip')) || '';
    return key in report ? report[key] : '';
  }

  return (
    <Box p={2}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Typography variant="h4" align="center" gutterBottom pt={2}>
        Garage Reports
      </Typography>
        {reports && garageLocation && (
          <>
            {!!Object.keys(reports[garageLocation] || []).length && Object.keys(reports[garageLocation] || []).map((report: any) => (
              <Card key={report} sx={{ marginBottom: 2 }}>
                <CardContent>
                  <Typography variant="h6">{report}</Typography>

                  {Object.keys(reports[garageLocation][report]).filter(report => report.includes('.pdf')).map((reportType: any) => (
                    <div key={reportType}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{reportType}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Viewer
                            fileUrl={reports[garageLocation][report][reportType]}
                            plugins={[
                              defaultLayoutPluginInstance,
                            ]}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
                </CardContent>
                <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Link
                    href={getReportZipFileLink(reports[garageLocation][report])}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    Download
                  </Link>
                </CardActions>
              </Card>
            ))}

            {!Object.keys(reports[garageLocation] || []).length && (
              <Card>
                <CardContent>
                  No reports available
                </CardContent>
              </Card>
            )}
          </>
        )}
      </Worker>
    </Box>
  )
};

export default Reports;