import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import useEndpoint from '../hooks/endpoint.hook';


import { useGarageLocation } from './Location';

type CarNumberContextType = {
  carNumber: string;
  carNumbers: string[];
  carNumberSelected: boolean;
  setTheCarNumber: (carNumber:string) => void;
};

const CarNumberContext = createContext<CarNumberContextType | null>(null);

export const useCarNumber = () => {
  return useContext(CarNumberContext);
}; 

type CarNumberProviderProps = {
  children: ReactNode;
};

export const TeslaCarNumberProvider = ({ children }: CarNumberProviderProps) => {
  const endpoint = useEndpoint();
  const { garageLocation, garageLocationSelected } = useGarageLocation()!;

  const [carNumbers, setCarNumbers] = useState(['']);
  const [carNumber, setCarNumber] = useState('');
  const [carNumberSelected, setCarNumberSelected] = useState(false);

  const setTheCarNumber = (car: string) => {
    setCarNumber(car);
    setCarNumberSelected(true);
  };

  useEffect(() => {
    if (garageLocationSelected) {
      endpoint?.get(`tesla/api/get_cars_by_location?location=${garageLocation}`)
      .then(response => {
          console.log('cars:', response.data);
          const cars = response.data.map((car: any) => (car.card_num));
          console.log('cars:', cars);
          setCarNumbers(cars);
      }).catch(error => {
        if (error.response) {
          console.error('There was an error!', error.response.data.message);
        }
      });
    }
  }, [garageLocation, garageLocationSelected, endpoint]);

  const value = {
    carNumber,
    carNumbers,
    setTheCarNumber,
    carNumberSelected,
  };

  return <CarNumberContext.Provider value={value}>{children}</CarNumberContext.Provider>;
};
