import React from 'react';
import Alert from '@mui/material/Alert';

interface AlertMessageProps {
  message: string | object;
  isErrorMessage: boolean;
}

function jsonToHtmlPrettyPrint(json: any) {
  // Convert JSON object to a string with indentation
  let jsonString = JSON.stringify(json, null, 2);

  // Convert special characters to HTML entities to safely insert the string into HTML
  let htmlString = jsonString
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/\n/g, '<br>') // Newlines to <br>
    .replace(/ /g, '&nbsp;'); // Spaces to &nbsp;

  // Wrap in <pre> tag to preserve whitespace and formatting
  return { __html: `<pre>${htmlString}</pre>` };
}

const AlertMessageComponent: React.FC<AlertMessageProps> = ({ message, isErrorMessage }) => {
  if (message) {
    let content;
    if (typeof message === 'object') {
      const htmlContent = jsonToHtmlPrettyPrint(message);
      content = <span dangerouslySetInnerHTML={htmlContent}></span>;
    } else {
      content = message;
    }
    return (
      <div>
        <Alert severity={isErrorMessage ? 'error' : 'success'}>{content}</Alert>
      </div>
    );
  }
  return null;
};

export default AlertMessageComponent;
