import React, { useState, useEffect } from 'react';

import {
  Card,
  Box,
  Typography,
  CardContent,
  Grid,
  CardMedia,
  CircularProgress,
} from '@mui/material';

import { useGarageLocation } from '../components/Location';
import { useEndpoint } from '../hooks/endpoint.hook';
import { Endpoint } from '../util/endpoint.util';

const IMAGE_PLACEHOLDER = 'https://user-profile-picture-uploads.s3.us-west-1.amazonaws.com/car-pictures/MobileConnector_98.jpg'

interface Car {
  id: string;
  createdAt: Date;
  auth0_sub: string;
  card_num: string;
  location: string;
  vehicle_picture: string | null; // Assuming vehicle_picture can be null
  vehicle_type: string;
}

const Media = () => {
  const [cars, setCars] = useState<Car[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const endpoint = useEndpoint();
  const { garageLocation } = useGarageLocation()!;

  const getCars = (garageLocation: string, endpoint: Endpoint) => {
    return endpoint?.get('admin/api/cars', {
      params: {
        location: garageLocation,
      }
    })
      .then(response => response.data)
      .catch(console.error)
  };

  useEffect(() => {
    endpoint && garageLocation && getCars(garageLocation, endpoint).then(cars => setCars(cars as unknown as any));
  }, [endpoint, garageLocation]); 

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>, carId: string) => {
    const file = event.target.files?.[0];

    if (file && endpoint) {
      try {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('picture', file);

        await endpoint?.put('admin/api/car-picture', {
          params: {
            smartcar_user_id: carId,
          },
          data: formData,
        })
        await getCars(garageLocation, endpoint).then(cars => setCars(cars as unknown as any));
      } catch (error) {
        console.error('Error uploading image:', error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {cars.map((car) => (
          <Grid item xs={12} sm={6} md={4} key={car.id}>
            <Card>
              <CardMedia
                component="img"
                height="194"
                image={car.vehicle_picture || IMAGE_PLACEHOLDER}
                alt={car.vehicle_type}
              />
              <CardContent>
                {car.vehicle_type && (
                  <>
                    <Typography variant="h6" component="div">
                      Vehicle Type
                    </Typography>
                    <Typography variant="h5" component="div">
                      {car.vehicle_type}
                    </Typography>
                  </>
                )}
                {car.card_num && (
                  <>
                    <Typography variant="h6" component="div">
                      Card Number
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {car.card_num}
                    </Typography>
                  </>
                )}

                <label htmlFor="file-input" className="file-input-label">
                  <span>Upload Image</span>
                </label>
                <input
                  id="file-input"
                  type="file"
                  className="file-input"
                  onChange={(event) => handleImageUpload(event, car.id)}
                />
                {isUploading && <CircularProgress size={24} />}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Media;